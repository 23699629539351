@use '../qdc-variables.scss' as *;

.info-icon {
  color: $secondaryBlue;
}

.mins {
  color: $secondaryBlue;
}

.free-mins {
  color: $yellow;
}

.credit-card {
  position: absolute;
  left: 90%;
  top: 30%;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.yellow {
  color: $yellow;
}

.orange {
  color: $orange;
}

.blue {
  color: $secondaryBlue;
}

q-icon.reserved {
  align-self: center;
  padding-right: 5px;

  svg {
    height: 20px;
    width: 20px;
    fill: $gold;
    stroke: $cardBgColor;
    stroke-width: 2;
  }
}

q-icon.reserved-top {
  align-self: flex-start;
  padding-top: 2px;

  svg {
    height: 20px;
    width: 20px;
    fill: $gold;
    stroke: $cardBgColor;
    stroke-width: 2;
  }
}

q-icon.intro-video-play {
  svg {
    fill: $white;
    stroke-width: 1px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 1);
    border-radius: 50%;

    circle {
      stroke: $white;
    }

    polygon {
      stroke: $primaryColor;
    }

    &:hover {
      background: $opaqueWhite50;
      border-radius: 50%;
    }
  }
}

// Custom Q Buttons
q-button.cancel-session {
  button.q-button--root.fill-primary {
    min-height: 24px;
    padding: 6px 8px;
  }
}

q-button.landing-page-opaque {
  button {
    min-width: max-content;
  }

  .q-button--outline {
    // TODO: #565656 or #6F7073
    border-color: #565656;

    &:hover {
      background-color: var(--q-button-background-hover);
    }
  }

  .q-button--outline.q-neutral {
    color: $white;
  }
}

q-button.end-session {
  button.q-button--root.fill-negative {
    background-color: $endSessionColor;
    min-height: 24px;
    padding: 6px 8px;
  }
}

q-button {
  button.quick-tour-disabled {
    color: rgba(255, 255, 255, 1) !important;
  }
}

q-button.no-padding {
  button.q-button--ghost.q-button--root.q-neutral.q-size-s {
    padding: 0;
  }
}

q-icon-button.no-padding {
  button.q-button--ghost.q-icon-button--root.q-primary.q-shape-square.q-size-l {
    padding: 0;
  }
}

q-icon-button.dark-blue {
  .q-button--ghost.q-neutral {
    color: $copyIconColor;
    border: 1px solid $copyIconBorderColor;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
  }
}

q-icon-button.normal {
  .q-button--ghost.q-neutral {
    color: $iconColorDark;
  }
}

q-button.interactive-session-button {
  .q-button--ghost.q-primary {
    color: $toolbarGhostButtonColor;
  }

  .q-button--ghost.q-disabled {
    color: $toolbarDisabledButtonColor;
  }
}

q-icon-button.float-button {
  top: 310px;
  left: 308px;
  position: absolute;
  margin: 0;
  border: none;
  background-color: transparent;

  &.single {
    top: 115px;
    left: 290px;
  }

  &.second {
    top: 350px;
  }

  &.third {
    top: 390px;
  }

  &.inactive {
    .q-icon-button--root.q-size-l {
      color: $scrcpyGray
    }
  }

  button.q-button--ghost {
    &:hover {
      background-color: inherit;
    }

    &:focus-visible {
      background-color: inherit;
      border: none;
      outline: none;
    }
  }

  .q-icon-button--root.q-size-l {
    padding: 0;
    color: $scrcpyGreen;

    .q-icon {
      width: 30px;
    }
  }
}

q-status {
  &.normal-font-weight {
    label.q-status--label {
      font-weight: 400;
    }
  }

  &.cursor-pointer {
    label.q-status--label {
      cursor: pointer;
    }
  }
}

// TODO: remove this override when QUI is patched
.q-progress-circle--outer-circle.q-indeterminate {
  transition: all 0s ease-in-out;
}


.q-card--root.q-orientation-vertical {
  padding: 15px 20px;
}

.q-card--root {
  border-radius: 5px;
}

.q-elevation-2 {
  background-color: $deviceCardBgColor;
}


q-device-card {

  div.q-device-card--root.q-elevation-2.q-has-top-section {
    width: 252px;
    max-height: 400px;
    background-color: $deviceCardBgColor;
  }

  &.hoverable {
    div.q-device-card--root.q-elevation-2.q-has-top-section {
      cursor: pointer;

      &:hover {
        background-color: $deviceCardHoverBgColor;
      }
    }
  }

  &.checked {
    div.q-device-card--root.q-elevation-2.q-has-top-section {
      box-shadow: inset 0px 0px 0px 0.2rem $primaryColor;
      border-radius: 4px;
    }
  }

  &.disabled-card {
    pointer-events: none;

    div.q-device-card--root.q-elevation-2.q-has-top-section {
      opacity: .4;
    }
  }

  &.disabled-available {
    pointer-events: none;
  }

  div.q-device-card--root.q-elevation-2.q-has-top-section {
    div.q-device-card--footer {
      padding: 8px 32px 0;
    }
  }

  .q-device-card--label {
    display: flex;
    min-height: 50px;
    align-items: center;
    text-align: center;
    white-space: break-spaces;
  }
}

q-divider {
  &.options-divider {
    div {
      padding: 4px 0;
    }
  }
}

q-overlay-panel {
  .q-overlay-panel-popup--root {
    background: $overlayBgColor;

    .q-overlay-panel--arrow {
      background: $overlayBgColor;
    }
  }

  // Top Bar at z-index 2000
  &.top-bar-overlay {
    .q-overlay-panel-popup--root {
      z-index: $topbarZIndex + 1 !important;
    }
  }

  // Style fixes for floating-ui bugs
  &.compute-file-browser-fix {
    .q-overlay-panel--arrow.q-top-start {
      right: unset;
      left: 20px;
    }
  }

  &.compute-help-fix {
    .q-overlay-panel--arrow {
      left: unset !important;
      right: -5px !important;
    }
  }
}

q-popover {
  .q-popover--root {
    background: $overlayBgColor;
  }
}

// QUI Table
.q-table--tr {
  background-color: var(--cardBgColor, var(--secondaryDarkColor, #fff));
}


// Q-Portal contains styling overrides for q-tooltips and q-overlays
q-portal {
  div#q-portal-root {

    // Username Tooltip
    q-tooltip-content#username-tooltip.q-fade.q-tooltip--left.q-tooltip--root.show {
      top: 15px !important;
    }

    // Cart Tooltips
    q-tooltip-content#cart-tooltip {
      z-index: 100001 !important; // Must be larger than .raised class
    }

    .q-overlay-panel--arrow {
      background: $overlayBgColor;
    }

    // Job Details
    q-tooltip-content-v2#badge.q-fade.q-tooltip--root.q-tooltip--top.show {
      z-index: unset !important;
    }
  }
}

.q-button--fill.q-disabled {
  background-color: $disabledColor ;
  color: $buttonColorPrimaryTextDisabled;
}

.q-button--root,
.q-icon-button--root {
  transition-duration: 0ms !important;
}

q-button.help-option-button {
  button.q-button--ghost.q-button--root.q-neutral.q-size-m {
    width: -webkit-fill-available;
    width: -moz-available;
    place-content: start;
  }
}

// Inline Alert
q-inline-alert {
  &.device-busy {
    margin-top: $topbarHeight;
    position: fixed;
    z-index: 100;
    width: -webkit-fill-available;
    width: -moz-available;
    background-color: $q-informative;

    .q-inline-alert--root {
      padding: 16px;

      q-icon.q-icon {
        color: $black;
      }
    }

    .q-inline-alert--label {
      color: $black;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &.device-busy-slim {

    .q-inline-alert--root {
      padding: 16px;
    }

    .q-inline-alert--label {
      color: var(--q-text-1-secondary);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

// Text Area
q-text-area {
  &.form-text-area {

    div.q-text-area--root {
      width: -webkit-fill-available;
      width: -moz-available;
    }
  }
}

.address-update-button {
  pointer-events: none;
  opacity: 0.8;
  font-size: 12px !important;
  line-height: 18px;
}

.q-notification-animator--root {
  z-index: 4000;
}

.q-tooltip--label {
  font-size: $mediumFontSize;
}

q-header.top-bar-menu {
  .q-header--root.q-color-primary {
    background: $topbarBgColor;
    border-bottom: 1px solid $topBarBorder;
  }

  .q-header--content {
    width: 100%;
    height: $toolbarHeight;
    z-index: $topbarZIndex;
    position: fixed;
    background-color: inherit;
    top: 0;
    gap: 35px;
  }
}


.q-badge--root.q-size-s.q-kind-counter {
  padding: 2px 4px;
  margin-top: -6px;
  font-size: 8px;
}

// QUI Stepper

.q-stepper--indicator.q-variant-counter {
  width: 28px;
  height: 28px;
  padding: 2px 8px;
  background: $stepper-background;
  border: 1px solid $step-square-border;
  border-radius: 4px;
  color: $stepper-square-color;
  font: inherit;
  font-size: 19px;
  min-width: unset;
  min-height: unset;

  &.q-active {
    background-color: $step-square-active-background;
    color: $step-square-active-color;
    border: 1px solid $step-square-border;
  }

  &.q-status-complete:not(.q-active) {
    opacity: 0.8;
    background-color: $stepper-valid-icon-background;
    color: $stepper-square-color;
  }
}

.q-stepper--step {
  gap: 1rem;
}

.q-stepper--horizontal-bar,
.q-stepper--horizontal-bar.q-status-complete {
  width: 32px;
  background-color: $stepper-hr-border;
}

// Custom Q Accordion
q-accordion.faq-accordion {
  .q-accordion--root {
    background-color: inherit;
    padding: 0;
  }

  .q-accordion-item--root.q-separator {
    border-bottom: solid 1px #BBBBBB;
  }

  .q-accordion-item--title-bar {
    padding: 48px 12px;
  }

  .q-accordion-item--end-icon {
    color: $q-informative;
  }

  .q-accordion-item--label {
    color: $accordionFontColor;
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
  }

  .q-accordion-item--description {
    color: $accordionFontColor;
    text-align: left;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 10px 48px;

    @media (min-width: 1000px) {
      width: 80%;
    }

    @media screen and (min-width: 1400px) {
      width: 60%;
    }
  }
}

q-dialog-v2 {

  .q-dialog--modal {
    border-radius: 6px;
  }

  .q-dialog--content-container.q-size-m {
    background: $primaryLightColor;
    border-radius: 6px;
  }

  &.ssh {
    .q-dialog--content-container.q-size-m {
      height: 100%;
    }

    .q-dialog--content {
      padding: 0 1.5rem 2rem;
      overflow-y: auto;
    }
  }


  &.model {
    .q-dialog--modal-backdrop {
      z-index: 1101;
      background: $dialogBackDropColor;
    }
  }

  &.full-screen {
    div .q-dialog--footer {
      background: $primaryLightColor;
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    div .q-dialog--content-container.q-size-m {
      height: 100%;
      width: 100%;
      padding: 0 0;
      background: $bodyBgColor;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    div .q-dialog--content {
      padding: 0 1.5rem 2rem;
    }

    &.content {
      div .q-dialog--content-container.q-size-m {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background: $primaryLightColor;
      }

      div .q-dialog--content {
        padding: 1.5rem 12rem;
      }
    }
  }

  &.notes-dialog {
    div .q-dialog--modal {
      min-width: 50%;
    }
  }

  &.request-mins {
    div .q-dialog-content {
      min-height: 180px;
      align-content: space-evenly;
      text-align: -webkit-center;
      text-align: -moz-center;
    }
  }

  &.create-snapshot {
    div .q-dialog--content-container.q-size-m {
      width: max-content;
    }
  }

  &.device-card-options {
    div .q-dialog--modal {
      width: min-content;
    }

    div .q-dialog--content-container.q-size-m {
      width: min-content;
    }
  }

  &.quick-tour-dialog {
    div .q-dialog--content-container.q-size-m {
      padding: 0;
    }

    div .q-dialog--footer {
      padding: 40px;
    }
  }
}

.q-skeleton--root {
  background-color: $skeletonColor;
}

q-dialog-v2 {

  .q-dialog--content-container {
    background: $primaryLightColor;
  }

  &.ssh-dialog {
    .q-dialog--content-container {
      min-width: 1328px;
    }

    .q-dialog--footer {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      padding-right: 40px;
    }
  }
}

@use '../qdc-variables.scss' as *;

// this file contains all the overrides for primeng components

body,
body .p-component {
  font-family: $fontFamily, sans-serif;
}

p-card {
  .p-card {
    border-radius: $cardBorderRadius;
    margin: 0 0 16px 0;
    background-color: $cardBgColor;
    color: $textColor;

    .p-card-title {
      font-size: $largeFontSize;
      font-weight: 600;
    }

    .p-card-body {
      padding: 1em 1.25em;
    }
  }

  &.hoverable {
    .p-card {
      cursor: pointer;

      &:hover {
        background-color: $deviceCardHoverBgColor;
      }
    }
  }

  &.home-welcome-card {
    .p-card .p-card-body {
      padding: 0;
    }

    .p-card .p-card-content {
      padding: 5px 0;
    }
  }

  &.minutes-card {

    div.p-card {
      height: 80px;

      .p-card-body {
        padding: 1em 0em;
      }

      div.p-card-content {
        padding: 1rem;
        margin-top: -36px;
      }
    }
  }

  &.operation-device-card {
    cursor: pointer;
    border-radius: 4px;
    height: 185px;
    width: 185px;
    padding: 2.25rem 0;
  }

  &.operation-mode-card {
    .p-card {
      height: 100%;
    }

    .p-card-body {
      padding: 30px 2em 20px;
    }
  }

  &.device-option-card {
    .p-card {
      height: 185px;
      width: 185px;
    }

    .p-card-content {
      padding-top: 30px;
    }

  }

  &.clicked {
    .p-card {
      box-shadow: 0px 0px 0px 2px $primaryColor;
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.ui-toolbar-group-left {
  font-size: $largeFontSize;
  line-height: $xLargeLineHeight;
  font-weight: bold;
  max-width: 70%;
  padding-left: 20px;
}

.ui-toolbar-group-right {
  font-size: $largeFontSize;
  line-height: $xLargeLineHeight;
  font-weight: bold;
  max-width: 70%;
  padding-right: 20px;
}

button.p-element.p-splitbutton-defaultbutton {
  background-color: $darkerGray;
  border: 0;
  border-radius: 0;

  &:enabled:hover,
  &:focus {
    background: $darkGray;
  }
}

button.p-element.p-splitbutton-menubutton {
  display: none;
  background-color: $darkerGray;
  border: 0;
  border-radius: 0;

  &:hover,
  &:focus {
    background: $darkGray;
    box-shadow: none;
  }
}

.minutes-used-icon {
  margin-left: 0.5rem;
}

// Button Styles
.p-button {
  border: none;
  box-shadow: none;
  color: $white;
  background-color: $buttonColorPrimary;
  border-radius: 2px;

  .p-button-label {
    font-weight: 500;
    font-size: 14px;
    padding: 0.75rem 1.25rem;

    &:disabled {
      color: $buttonColorPrimaryTextDisabled;
    }
  }

  &:enabled {
    box-shadow: none;

    &:hover,
    &:focus-visible {
      background-color: $buttonColorPrimaryHover;
    }
  }

  &:disabled {
    background-color: $buttonColorPrimaryDisabled;
    color: $buttonColorPrimaryTextDisabled;
  }

  &.p-button-outlined {
    border: none;
    box-shadow: none;
    background: transparent;
    color: $textColor;
    border: none;

    &:enabled {
      box-shadow: none;

      &:hover,
      &:focus-visible {
        background: transparent;
        border: none;
      }
    }

    >mat-icon {
      color: $darkGray;
    }
  }

  &.cancel {
    min-width: 75px;

    &:enabled {
      color: $cancelColor;
      background-color: white;
      border: 2px solid $cancelColor;

      &:hover,
      &:focus-visible {
        color: $cancelDarkColor;
        background-color: $cancelLightColor;
        border: 2px solid $cancelDarkColor;
      }
    }
  }

  &.ghost {
    min-width: 75px;

    &:enabled {
      color: $buttonColorGhostEnabled;
      background-color: transparent;
    }
  }

  &.smaller {
    font-size: .875rem;
    padding: .5rem 1rem;
    background-color: #495057;

    &:hover,
    &:focus {
      background-color: #7b858f;
    }
  }

  // Used for the Clear All button in Files/Pager Component
  &.filter-chip-clear-all {
    vertical-align: middle;
    font-size: 12px;
    padding: .375rem 1rem;
    background-color: $primaryColor;

    .p-button-label {
      font-weight: 600;
    }
  }

  &.icon {
    background-color: $primaryLightColor;
    padding: 5px;

    &:hover,
    &:focus {
      background-color: $highlightColor;
    }
  }

  &:disabled {
    opacity: 1;
    background-color: $disabledColor;

    &:hover,
    &:focus {
      background: $disabledColor;
    }
  }

  // Log Buttons
  &.download {
    width: 45px;
    height: 30px;
    background-color: inherit;

    &:enabled {
      background-color: inherit;

      &:hover {
        background: inherit;
      }
    }

    &:disabled {
      mat-icon {
        color: $lighterGray;
      }

      background-color: inherit;

      &:hover {
        background-color: inherit;
      }
    }

    &:active {
      background-color: inherit;
    }

    span.p-button-icon.p-button-loading-icon.pi.pi-spinner.pi-spin {
      color: $darkerGray;
    }
  }

  // Used for icons in the files table name cells
  &.icon-dark {
    margin-right: 10px;
    color: $lighterGray;
    background: inherit;

    &:enabled:hover {
      background: inherit;
    }

    &:disabled:hover {
      background: inherit;
    }

    &:active {
      background-color: inherit !important;
    }
  }

  // End of Log Buttons

  // Buttons with no border/fill and have an icon to the left of it
  &.icon-button {
    font-size: 1rem;
    padding: .5rem 1rem;
    background-color: transparent;
    box-shadow: none;
    color: $buttonTextColor;

    &.yellow {
      color: $yellow;

      &:hover {
        background-color: transparent;
        color: $iconDarkYellow;
        font-weight: 500;
      }

      &:active {
        background-color: transparent;
        color: $iconDarkYellow;
        font-weight: 500;
      }
    }

    &.orange {
      color: $orange;

      &:hover {
        background-color: transparent;
        color: $iconDarkOrange;
        font-weight: 500;
      }

      &:active {
        background-color: transparent;
        color: $iconDarkOrange;
      }
    }

    &.red {
      color: $red;

      &:hover {
        background-color: transparent;
        color: $iconDarkRed;
        font-weight: 500;
      }

      &:active {
        background-color: transparent;
        color: $iconDarkRed;
        font-weight: 500;
      }
    }

    &.gray {
      color: $gray;
      opacity: $ghostOpacity;

      &:hover {
        color: $gray;
      }

      &:active {
        color: $gray;
      }
    }

    &.disabled {
      color: $buttonColorPrimaryTextDisabled;
      opacity: $ghostOpacity;
    }

    .p-button-label {
      font-weight: 500;
      color: $textColor;
      padding: 0.75rem 1.25rem;

      &.disabled {
        color: $buttonColorPrimaryTextDisabled;
      }
    }

    .p-button-icon {
      font-size: 1.25rem;
    }

    // Needed to unset primeng's native behaviors
    &:hover {
      background-color: transparent;
      color: $buttonHoverColor;
      font-weight: 500;
    }

    &:active {
      background-color: transparent;
      color: $primaryDarkColor;
    }

    &.crash-icon {
      padding: 0;

      >.p-button-icon {
        font-size: 12px;
        margin-right: 4px;
      }

      >.p-button-label {
        font-size: 12px;
        color: $textColor;
      }
    }
  }

  // Buttons with no border/fill and have an icon
  &.icon-button-link-landing {
    background-color: transparent;
    color: $textColor;
    padding: 5px 15px 5px 5px;
    font-weight: 600;

    &.sm {
      .p-button-label {
        font-size: 12px;
        letter-spacing: 0.01em
      }
    }

    // Needed to unset primeng's native behaviors
    &:hover {
      background-color: transparent;
      color: $textColorHover;
      font-weight: 400;
      box-shadow: none;
    }
  }

  &.icon-button-link-home {
    background-color: transparent;
    padding: 5px 0px 5px 5px;
    font-weight: 600;

    &.sm-color {
      color: $buttonColorPrimary;

      .p-button-label {
        font-size: 12px;
        letter-spacing: 0.01em
      }
    }

    &.sm {
      color: $textColor;

      .p-button-label {
        font-size: 12px;
        letter-spacing: 0.01em
      }
    }

    &:disabled {
      color: $buttonColorPrimaryTextDisabled;
    }

    // Needed to unset primeng's native behaviors
    &:hover {
      background-color: transparent;
      color: $buttonHoverColor;
      font-weight: 400;
      box-shadow: none;
    }

    &:enabled {
      box-shadow: none;

      &:hover,
      &:focus-visible {
        color: $buttonColorPrimaryHover;
      }
    }
  }

  &.outline {
    background-color: transparent;
    border: 1px solid $buttonBorderOutlineColor;
    color: $textColor;

    .p-button-label {
      font-weight: 400;
    }

    // Needed to unset primeng's native behaviors
    &:hover {
      background-color: $buttonOutlineHover;
      color: $textColorHover;
      font-weight: 400;
      box-shadow: none;
      border: 1px solid $buttonBorderOutlineColor;
    }
  }
}

.p-multiselect {
  background: $bodyBgColor;
  border: unset;

  .p-multiselect-trigger {
    width: unset;
  }

  .p-multiselect-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    font-stretch: 110;
    font-variation-settings: $fontVariationSettings;
    color: $textColor;
  }
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: $textColor;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: $textColor;

  &.p-highlight {
    color: $textColor;
    background: transparent;
  }

  &:not(.p-highlight):not(.p-disabled):hover {
    color: $primaryColor;
    background: transparent;
  }
}

.p-multiselect-panel {
  background: $overlayBgColor;
  box-shadow: 0px 12px 24px $boxBorder;
  border-radius: 4px;
  width: 260px;

  .p-multiselect-header {
    background: $overlayBgColor;
  }

  .p-multiselect-item {
    height: 36px;
    align-items: center;
    padding: 8px 16px 8px 32px;
    gap: 8px;
  }

  .p-checkbox .p-checkbox-box {
    width: 16px;
    height: 16px;
    border: 1px solid $checkBoxBorder; // rgba(18, 19, 25, 0.3);
    border-radius: 2px;
    margin-top: 0.3em;
    ;
  }

  // .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container
  .p-inputtext {
    padding: 8px 12px;
    gap: 8px;
    width: 225px;
    height: 36px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(18, 19, 25, 0.3);
    border-radius: 4px;
  }

  // .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container
  .p-multiselect-filter-icon {
    width: 16px;
    height: 16px;
  }

  .p-multiselect-items .p-multiselect-empty-message {
    color: $textColor;
  }
}

.p-checkbox .p-checkbox-box {
  background: transparent;
  color: $textColor;
  border: 2px solid $checkBoxBorder;

  &.p-highlight {
    background: #3253DC;
  }
}

.p-column-filter-operator {
  background: $overlayBgColor !important;
  padding: 0.75rem 1.25rem 0 1.25rem !important;
  border: none !important;
  color: $textColor;
}

.p-column-filter-overlay {
  background: $overlayBgColor;
  color: $textColor;
}

.p-column-filter-constraints {
  p-columnfilterformelement {
    input.p-inputtext {
      border: solid 1px $primaryColor;
    }
  }
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #fff;
  color: $primaryLightColor;
  border-color: $primaryColor;
  align-content: center;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 500;
  color: $primaryLightColor;
}

.p-steps .p-steps-item:before {
  border-top: 1px solid #f1f1f17a;
}

p-splitbutton.p-element {
  align-self: center;
}

.p-splitbutton.p-button-sm>.p-button {
  font-size: 0.875rem;
  padding: 5px 0;
  align-self: center;
}

.p-button-sm {
  &.red {
    background: $endSessionRed;

    &:hover {
      background: $endSessionHoverRed;
    }

    &:active {
      background: $endSessionRed;
    }
  }
}

.p-tabmenu {
  max-height: 50px;

  .p-tabmenu-nav {
    border-width: 0 0 0 0;
    max-height: 50px;

    .p-menuitem-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      display: flex;
      align-items: center;
      letter-spacing: 0.01em;

      /* Neutral/Neutral 75 */

      color: $topbarMenuFontColor;
      font-stretch: 110;
      font-variation-settings: $fontVariationSettings;
    }

    .p-tabmenuitem.p-highlight {
      color: $topbarFontColor;

      .p-menuitem-link {
        background: $topbarBgColor;
        border-color: unset;
        color: $topbarMenuFontColor;
      }

      .p-menuitem-link:focus {
        border-color: unset;
        box-shadow: none;
        background: $topbarBgColor;
      }
    }

    .p-tabmenuitem {
      max-height: 50px;

      .p-menuitem-link {
        background: $topbarBgColor;
        max-height: 50px;
        border-top-right-radius: unset;
        border-top-left-radius: unset;
        font-weight: 400;
        font-size: 16px;
        color: $topbarMenuFontColor;
      }
    }

    .p-menuitem-link:not(.p-disabled):focus {
      box-shadow: unset;
    }

    .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
      background: $topbarBgColor;
      border-color: unset;
      color: $topbarMenuFontColorHover;
    }
  }
}

// Divider overrides
.p-divider-solid.p-divider-horizontal:before {
  border-top-style: solid;
}

.p-divider-dashed.p-divider-horizontal:before {
  border-top-style: dashed;
}

.p-divider-solid.p-divider-vertical:before {
  border-left-style: solid;
}

.p-inputtext {
  padding: 0.5em 1rem;
  color: $textColor;
}

p-inputnumber,
.p-inputnumber {
  width: 100%;

  .p-inputnumber-input {
    border: none;
  }
}

.p-calendar,
.p-inputtext {
  width: 100%;
  font-family: $fontFamily, sans-serif;
  background: transparent;
}

.p-inputgroup {
  background-color: transparent;

  .p-inputgroup-addon {
    padding: 4px 0px 4px 0px;
    border-radius: 0px;
    border-right: 1px solid $borderColor;
    border-bottom: 0px;
    background-color: transparent;
    width: 100%;

    >.p-input-icon-left {
      >span {
        position: absolute;
        top: 50%;
        font-family: $fontFamily, sans-serif;
        margin-top: -8px;
        border: none;
      }

      >span:first-of-type {
        left: 3rem;
        background-color: transparent;
        border: none;
        font-weight: 500;
      }

      >i.pi.pi-credit-card {
        margin-left: 90%;
      }

      >.p-inputtext {
        width: 100%;
        font-family: $fontFamily, sans-serif;
        font-size: 14px;
        background-color: transparent;
        border: none;
        box-shadow: none;
      }

      >.p-inputtext:last-of-type {
        width: 100%;
        font-family: $fontFamily, sans-serif;
        font-size: 14px;
        background-color: transparent;
        border: none;
        box-shadow: none;

        &.card-exp {
          margin-right: 0px;
          padding-right: 0px;
          width: 55%;
        }
      }
    }

    >.p-input-icon-right {
      >span {
        position: absolute;
        top: 50%;
        font-family: $fontFamily, sans-serif;
        margin-top: -8px;
        border: none;
      }

      >span:first-of-type {
        left: 3rem;
        background-color: transparent;
        border: none;
        font-weight: 500;

      }

      >.p-inputtext {
        width: 100%;
        font-family: $fontFamily, sans-serif;
        font-size: 14px;
        background-color: transparent;
        padding-left: 8rem;
        border: none;
        box-shadow: none;
      }
    }
  }

  .p-inputgroup-addon:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &.split {
      border-radius: 0px;
    }

    &.splitLast {
      border-radius: 0px;
      border-bottom: 1px solid $borderColor;
    }
  }

  .p-inputgroup-addon:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &.split {
      border-radius: 0px;
      border-left: 0px;
      border-bottom: 0px;
    }

    &.splitLast {
      border-radius: 0px;
      border-left: 0px;
      border-bottom: 0px;
      border-bottom: 1px solid $borderColor;
    }
  }
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup>.p-inputwrapper:last-child>.p-component,
.p-inputgroup>.p-inputwrapper:last-child>.p-component>.p-inputtext {
  border-radius: 0px;
  border-bottom: 1px solid $borderColor;
}

.p-chip {
  border-radius: 8px !important;
}

::ng-deep {

  // Component Overlay
  .p-component-overlay {
    background-color: $overlayBgOpaqueColor;
  }

  .pi-spin {
    color: $primaryColor !important;
  }

  // Tabview
  p-tabView {
    div.p-tabview-nav-container {
      display: flex;
      padding: 0px 20px;

      .p-tabview-nav-link {
        color: $textFadedColor;

        .p-tabview-title {
          font-size: 14px;
          font-weight: 600;
        }
      }

      div.p-tabview-nav-content {
        color: $iconColorDark;
        background: transparent;

        ul.p-tabview-nav {
          color: $iconColorDark;
          background: transparent;

          li {
            a {
              background: transparent;
              // color: $iconColorDark;
              // border-color: $iconColorDark;
            }

            .p-tabview-nav-link {
              background: transparent;

              &:not(.p-disabled):focus {
                box-shadow: none;
              }
            }

            &:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
              background: transparent;
            }

            .p-highlight {
              color: $textColor;
            }

          }

          li.p-tabview-ink-bar {
            display: none;
          }
        }
      }

      button.p-element {
        &.p-tabview-nav-next {
          position: relative;
          height: auto;
          color: $iconColorDark;
          box-shadow: none;
          background: transparent;
        }

        &.p-tabview-nav-prev {
          position: relative;
          height: auto;
          color: $iconColorDark;
          box-shadow: none;
          background: transparent;
        }
      }
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background: transparent;
      color: $iconColorDark !important;
      border-color: $iconColorDark;
    }
  }

  p-tabView.crashes-tabview {

    div.p-tabview-nav-container {
      display: flex;
      padding: 1.25rem 1rem 0;

      .p-tabview-title {
        font-weight: 600;
      }

      div.p-tabview-nav-content {
        color: $iconColorDark;

        ul.p-tabview-nav {
          color: $iconColorDark;

          li.p-highlight {
            a {
              color: $iconColorDark;
              border-color: $iconColorDark;
            }
          }

          li.p-tabview-ink-bar {
            display: none;
          }
        }
      }

      button.p-element {
        &.p-tabview-nav-next {
          position: relative;
          height: auto;
          color: $iconColorDark;
          box-shadow: none;
        }

        &.p-tabview-nav-prev {
          position: relative;
          height: auto;
          color: $iconColorDark;
          box-shadow: none;
        }
      }
    }

    div.p-tabview-panels {
      padding: 1rem 1rem;
    }
  }

  p-tabView.minutes-tabview {
    div.p-tabview-nav-container {
      display: flex;
      padding: 0;

      .p-tabview-title {
        font-size: 14px;
        //color: $textColor;
        //font-weight: 600;
      }

      div.p-tabview-nav-content {
        color: $iconColorDark;
        background: transparent;

        ul.p-tabview-nav {
          color: $iconColorDark;
          background: transparent;

          li {
            a {
              background: transparent;
              // color: $iconColorDark;
              // border-color: $iconColorDark;
            }

            .p-tabview-nav-link {
              background: transparent;

              &:not(.p-disabled):focus {
                box-shadow: none;
              }
            }

            &:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
              background: transparent;
            }

            .p-highlight {
              background: transparent;

              .p-tabview-nav-link {
                // background: transparent;
                color: $iconColorDark !important;
                border-color: $iconColorDark !important;
              }

              a {
                color: $iconColorDark;
                border-color: $iconColorDark;
              }
            }

          }

          li.p-tabview-ink-bar {
            display: none;
          }
        }
      }

      button.p-element {
        &.p-tabview-nav-next {
          display: none;
        }

        &.p-tabview-nav-prev {
          display: none;
        }
      }
    }

    .p-tabview-panels {
      padding: 0;
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background: transparent;
      color: $iconColorDark !important;
      border-color: $iconColorDark;
    }
  }

  // Breadcrumbs
  div.p-breadcrumb.p-component {
    border: none;
    padding-left: 0px !important;

    ul {
      li {
        max-width: 300px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        a.p-menuitem-link {
          box-shadow: none;

          span.p-menuitem-text {
            color: $iconColorDark;
            font-weight: 600;
          }
        }
      }

      li:last-child .p-menuitem-text {
        color: $primaryColor !important;
      }
    }
  }

  // Tree
  div.p-tree {
    border: none;
    background: $bodyBgColor;
    padding: 1.25rem 1.25rem 1.25rem 0;

    .p-tree-loading-icon {
      color: $primaryColor;
    }

    .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
      background: inherit;
    }

    span.p-treenode-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $textColor;
    }

    .p-tree-container .p-treenode .p-treenode-content {
      padding: unset;

      .p-tree-toggler {
        &:enabled:hover {
          background: transparent;
        }

        &:focus {
          box-shadow: none;
        }
      }

      &.p-highlight {
        background: $tableHoverColor;
        color: $textColorHover;
      }
    }

    .p-tree-empty-message {
      text-align: center;
    }
  }

  // Ace Editor
  .ace_text-layer {
    font-size: small !important;
    font-family: 'Roboto Mono' !important;
  }
}

.p-fluid .p-button {
  width: auto;
}

div.p-component-overlay.p-sidebar-mask.p-component-overlay-enter {
  top: $topbarHeight;
  opacity: 0;
}

.p-datatable .p-datatable-tbody>tr:focus {
  outline: none;
}


p-fileupload {
  .p-element.p-button.p-component.p-fileupload-choose.p-button-icon-only.p-fileupload-choose-selected {
    padding: 0.5rem 0;
    box-shadow: none;
  }

  .p-button-label {
    padding: 0 !important;
  }
}


// Mat Dialog
.confirm-dialog,
.ok-dialog {
  width: 480px;
  height: fit-content;
  padding: 12px 32px;
  background: $overlayBgColor;
  color: $darkerGray;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.warning-dialog {
  width: fit-content;
  height: fit-content;
  padding: 32px 32px 12px 32px;
  background: $overlayBgColor;
  color: $darkerGray;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.mat-mdc-dialog-container {
  .mdc-dialog__surface {
    background: transparent !important;
  }

  .mdc-dialog__title {
    padding: 0;
    font-family: $fontFamily !important;
    letter-spacing: 0.01em !important;
    color: $textColor !important;
    font-size: 22px !important;
  }

  .mdc-dialog__content {
    padding: 0 !important;
    letter-spacing: 0.01em !important;
  }

  .mdc-dialog__actions {
    padding: 8px 0;
  }

  .mat-mdc-dialog-actions {
    justify-content: flex-end;
  }
}

.cdk-overlay-container {
  z-index: 10000;
}

.p-panel {

  .p-panel-header,
  .p-panel-content {
    color: $textColor;
    background: $primaryLightColor;
    border: none;
    padding: 0;
  }
}

.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0;
}

// Table
.p-datatable {
  background: $tableBgColor;

  .p-datatable-thead {
    tr {
      th {
        line-height: 24px;
        font-weight: 400;
        font-size: 11px;
        text-align: left;
        height: 24px;
        padding: 0 16px;
        border-width: 1px 0;
        font-variation-settings: $fontVariationSettings;
        background-color: $tableHeaderBackgroundColor; //$primaryDarkerColor;
        color: $tableHeaderTextColor;
        border-bottom: 1px solid $titleBorderColor;
        border-top: 1px solid $titleBorderColor;

        // center the select all checkbox in table header
        &.checkbox {
          padding: 0;
          text-align: center;
        }

        .title {
          font-size: $defaultFontSize;
        }
      }
    }

    .p-sortable-column {
      .p-sortable-column-icon {
        color: $tableHeaderTextColor;
      }

      &.p-highlight {
        background: $primaryLightColor;
        color: $textColor;

        .p-sortable-column-icon {
          color: $textColor;
        }

        &:hover {
          background: $primaryLightColor;
          color: $textColor;

          .p-sortable-column-icon {
            color: $textColor;
          }
        }

        &:focus {
          box-shadow: inset 0 0 0 0.15rem $primaryColor;
        }
      }

      &:not(.p-highlight):hover {
        background: $primaryLightColor;
        color: $textColor;

        .p-sortable-column-icon {
          color: $textColor;
        }
      }
    }
  }

  .p-datatable-tbody {
    font-size: $smallFontSize;
    font-weight: 400;
    line-height: 16px;
    color: $textColor;
    letter-spacing: 0.01em;
    font-stretch: 110;
    font-variation-settings: $fontVariationSettings;

    >tr {
      height: $tableRowHeight;
      background-color: $tableBgColor;
      color: $textColor;
      border: 1px solid $tableBorderColor;
      border-top: 1px solid $textColor5;
      border-width: 1px 0;

      &.p-highlight {
        color: inherit;
        background-color: #3253DC;
      }

      td {
        white-space: nowrap;
        text-align: left;
        padding: 0 16px;
        border: none;

        * :not(.allow-overflow):not(.header):not(.p-datatable):not(.p-datatable-wrapper) {
          text-overflow: ellipsis;
        }
      }

      td:not(.allow-overflow) {
        div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    mat-icon {
      height: 20px;
      font-size: 1.3em;
      cursor: default;
    }
  }

  .p-datatable-footer {
    background-color: $tableBgColor;
    color: $textColor;
    border: none;
  }

  .p-datatable-tfoot>tr>td {
    font-size: $smallFontSize;
    font-weight: 400;
    line-height: 16px;
    background-color: $tableBgColor;
    color: $textColor;
    border: none;
  }


  tr td {
    background: $cardBgColor;
  }
}

.pi {
  font-size: 14px;
  color: $textColor;

  &.pi-clock.mins {
    font-size: $extraSmallFontSize;
    color: $secondaryBlue;
  }

  &.pi-clock.free-mins {
    font-size: $extraSmallFontSize;
    color: $yellow;
  }

  &.pi-clock.free-mins-lg {
    font-size: $smallFontSize;
    color: $yellow;
  }

  &.pi-refresh.retry {
    font-size: $smallFontSize;
  }

  &.pi-info-circle {
    color: $secondaryBlue;
  }

  &.pi-exclamation-triangle {
    color: inherit;
  }

  &.pi-flag-fill {
    color: inherit;
  }

  &.pi-server {
    color: inherit;
  }

  &.pi-shopping-cart.cart-image {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }

  &.pi-google {
    padding-left: 14rem;
    margin-right: -15rem;
  }

  &.pi-pencil {
    color: $secondaryBlue;
  }

  &.blue {
    color: $secondaryBlue;
  }

  &.green {
    color: $green;
  }

  &.red {
    color: $red;
  }

  &.yellow {
    color: $yellow;
  }

  &.orange {
    color: $orange;
  }

  &.grey {
    color: $gray;
  }

  &.turquiose {
    color: $teal;
  }

  &.filled {
    color: $white;
    font-size: 12px;
    padding: 5px;
    border-radius: 4px;

    &.status {
      &.green {
        background-color: $green;
      }

      &.blue {
        background-color: $secondaryBlue;
      }

      &.red {
        background-color: $red;
      }

      &.yellow {
        background-color: $yellow;
      }

      &.orange {
        background-color: $orange;
      }

      &.grey {
        background-color: $gray;
      }

      &.teal {
        background-color: $teal;
      }
    }

    &.green {
      background-color: $green;
    }

    &.red {
      background-color: $red;
    }

    &.yellow {
      background-color: $yellow;
    }

    &.orange {
      background-color: $orange;
    }

    &.grey {
      background-color: $gray;
    }

    &.teal {
      background-color: $teal;
    }
  }

  &.small {
    font-size: 11px;
  }
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  &.pi-folder {
    color: $teal;
  }

  &.blue {
    color: #4a91d9;
  }
}

.p-toolbar {
  padding: .5rem 1.25rem;
  border-radius: unset;
  border: 1px solid $tableBorderColor;
  border-width: 1px 0;
  height: 44px;
  background-color: $toolBarColor;

}

p-toolbar {
  &.job-report-toolbar {
    width: 100%;
    z-index: 10;

    div.p-toolbar {
      align-content: center;
      background-color: $jobReportToolbarColor;
      border-bottom: 1px solid $topBarBorder;

      span.title {
        color: $white;
      }
    }
  }

  &.file-viewer-toolbar {
    div.p-toolbar {
      align-content: center;
    }
  }
}

.p-paginator {
  background: unset;

  .p-paginator-pages .p-paginator-page {

    min-width: 2rem;
    height: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.37);
    font-weight: 500;
    color: $textFadedColor;
    margin: 0.143rem;

    &.p-highlight {
      background: $paginatorBackground;
      color: $paginatorColor;
    }

    &:not(.p-highlight):hover {
      border: 1px solid $paginatorHoverBorderColor;
      background: $paginatorBackground;
      color: $paginatorColor;
    }
  }

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    &:not(.p-disabled):not(.p-highlight):hover {
      color: $paginatorHoverIconColor;
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }
}

.p-sidebar {
  background-color: $primaryLightColor;
  top: $topbarHeight;
  height: 100vh;

  .p-sidebar-header {
    display: none;
  }

  .p-sidebar-content {
    overflow-x: hidden;
  }
}

// Figma based text classes
.text {
  font-weight: $mediumFontWeight;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  letter-spacing: 0.01em;
  font-variation-settings: $fontVariationSettings;
  color: $textColor;

  &.smaller {
    font-weight: $defaultFontWeight;
    font-size: 11px;
    line-height: 14px;
  }

  &.medium {
    font-weight: $defaultFontWeight;
    font-size: $mediumFontSize;
    line-height: 20px;
  }

  &.mediumV2 {
    font-weight: $defaultFontWeight;
    font-size: $defaultFontSize;
    line-height: 24px;
  }

  // TODO: refactor styles so larger has largerFontSize etc.
  &.larger {
    font-weight: $mediumFontWeight;
    font-size: $defaultFontSize;
    line-height: 20px;
  }

  &.larger-20 {
    font-weight: $mediumFontWeight;
    font-size: $largerFontSize;
    line-height: 20px;
  }

  &.largest-26 {
    font-weight: $mediumFontWeight;
    font-size: $largestFontSize;
    line-height: 32px;
  }

  &.largest {
    font-weight: $defaultFontWeight;
    font-size: $largestFontSize36;
    line-height: 44px;
  }

  &.largest-46 {
    font-weight: $defaultFontWeight;
    font-size: $largestFontSize46;
    line-height: 44px;
  }

  &.default-fw {
    font-weight: $defaultFontWeight;
  }

  &.medium-fw {
    font-weight: $mediumFontWeight;
  }

  &.large-fw {
    font-weight: $largeFontWeight;
  }

  &.larger-fw {
    font-weight: $largerFontWeight;
  }

  &.link {
    color: $linkColor;
  }

  &.faded {
    color: $textFadedColor;
  }

  &.nowrap {
    display: inline;
    white-space: nowrap;
  }

  &.red {
    color: $red;
  }

  &.white {
    color: $white;
  }

  &.primary {
    color: $primaryColor;
  }

  &.inverse-text-color {
    color: $inverseTextColor;
  }

  &.italic {
    font-style: italic;
  }
}

.p-togglebutton.p-button {
  border: none;

  &:not(.p-disabled):not(.p-highlight):hover {
    background: transparent;
  }

  &.p-highlight {
    background: transparent !important;
    color: black;

    &:hover {

      .p-button-icon-left,
      .p-button-icon-right {
        color: $topbarFontColor;
      }
    }

  }

  &:focus {
    box-shadow: none;
  }
}

.p-dropdown {
  background: transparent;
  border: 1px solid $borderColor;
  border-radius: 4px;

  .p-dropdown-header {
    background-color: $overlayBgColor;

    .p-dropdown-filter-container {
      background-color: $overlayBgColor;
      border: 2px solid $borderColor;
      border-radius: 4px;
    }
  }

  .p-dropdown-label,
  .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    text-align: left;
    color: $textFadedColor;
  }

  .p-dropdown-items-wrapper {
    background-color: $overlayBgColor;
  }
}

// Grid Icons
.p-column-filter-menu-button {
  color: $gridIcon;

  &:hover {
    background: $gridIconHover;
  }

  &.p-column-filter-menu-button-open {
    background: $gridIconHover;
  }

  &.p-column-filter-menu-button-active,
  .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    color: $gridIconActive;
    background: $gridIconHover;
  }
}

.sort-icon {
  color: $gridIcon !important;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &:hover {
    background: $gridIconHover;
  }

  &.sort-icon-active {
    color: $gridIconActive !important;
    background: $gridIconHover;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: $textColor;
  background: transparent !important;
  text-align: left;

  .p-highlight {
    color: $textColor;
    background: transparent;
  }

  &:not(.p-highlight):not(.p-disabled):hover {
    color: $primaryColor;
    background: transparent;

  }
}

.spanText {
  color: $textColor;
}

.fontFamily {
  font-family: $fontFamily, sans-serif;
}

.p-tabview .p-tabview-panels {
  background: transparent;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  background: $tableHoverColor;
}

// Carousel
p-carousel {
  &.home-carousel {
    .p-carousel-items-container {
      max-width: 65vw;
    }
  }
}

.p-carousel {

  .carousel-item {
    width: min-content;
    max-width: fit-content;
  }

  .p-carousel-content {

    .p-carousel-prev,
    .p-carousel-next {
      color: $carouselArrowColor;

      &:enabled:hover {
        color: $carouselArrowHoverColor;
        border-color: transparent;
        background: transparent;

        &:hover,
        &:focus {
          box-shadow: none;
        }

      }
    }
  }

  .p-carousel-indicators {
    padding: 1rem;
  }
}

.p-link:focus {
  box-shadow: none;
}

.p-inputswitch {
  .p-inputswitch-slider {
    background: $sliderBackground;
    border: 1px solid $borderColor;

    &:before {
      background: $sliderOffColor;
    }
  }

}

.p-inputswitch.p-inputswitch-checked {
  &:not(.p-disabled):hover .p-inputswitch-slider {
    background: $sliderOnBackground;
  }

  .p-inputswitch-slider {
    background: $sliderOnBackground;

    &:before {
      background: $sliderOnColor;
    }
  }
}

.automated-developer-info.p-overlaypanel {
  .p-overlaypanel-content {
    border-radius: 4px;
    background-color: $overlayBgColor;
  }

  &:before,
  &:after {
    border-block-end-color: $overlayBgColor;
  }

}

.interactive-developer-info.p-overlaypanel,
.session-extension.p-overlaypanel {
  .p-overlaypanel-content {
    border-radius: 4px;
    background-color: $primaryLightColor;
  }

  &:before {
    border-block-end-color: $primaryLightColor;
  }

  &:after {
    border-block-end-color: $primaryLightColor;
  }
}

// Progress Spinner
.p-progress-spinner {
  width: 200px;
  height: 200px;

  .p-progress-spinner-svg {
    width: 200px;
    height: 200px;
  }

  .p-progress-spinner-circle {
    animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
  }

  &.regular-spinner {
    margin-bottom: 60px !important;
    margin-right: 100px;
  }

  &.small-spinner {
    width: 2.8rem;
    height: 2.8rem;

    .p-progress-spinner-svg {
      width: 2.8rem;
      height: 2.8rem;
    }
  }
}

@keyframes custom-progress-spinner-color {

  100%,
  0% {
    stroke: $primaryColor;
  }

  40% {
    stroke: $primaryLightColor;
  }

  66% {
    stroke: $highlightColor;
  }

  80%,
  90% {
    stroke: $primaryLightColor;
  }
}

@keyframes custom-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

// Data view
p-dataview.devices-grid {
  .p-dataview {
    .p-dataview-emptymessage {
      padding: 0;
    }
  }
}

.p-dataview.p-dataview-list .p-dataview-content>.p-grid>div {
  border: none;
}

.p-dataview .p-dataview-content {
  background: transparent;
}

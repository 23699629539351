@use '../qdc-variables.scss' as *;
@use '../../../assets/scss/icons';
@use '../../../assets/scss/fonts';
@import "@qui/styles/dist/all.min.css";
@import "@qui/base/dist/all.min.css";

html {
  height: 100%;
  font-size: 14px;
  font-family: $fontFamily, sans-serif;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
  background: $bodyBgColor;

  // Tooltip
  .p-tooltip {
    &.p-tooltip-right .p-tooltip-arrow {
      border-right-width: 4px;
    }

    &.p-tooltip-left .p-tooltip-arrow {
      border-left-width: 4px;
    }

    &.p-tooltip-top .p-tooltip-arrow {
      border-top-width: 4px;
    }

    &.p-tooltip-bottom .p-tooltip-arrow {
      border-bottom-width: 4px;
    }
  }

  .clickable {
    cursor: pointer;
  }

  // main-toolbar applies to p-toolbars when wanting to put a fixed toolbar below the topbar and above content
  // combine with the content using the layout-content class
  .layout-main .main-toolbar {
    background: $bodyBgColor;
    color: $textColor;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid $dividersColor;
    box-shadow: none;
    padding: 15px 10px;
    height: $toolbarHeight;
    top: $topbarHeight;
    position: fixed;
    width: 100%;
  }

  .layout-main .layout-content {
    min-height: 100px;
    top: 5px;
    position: relative;
    padding: 20px 0 20px 0;
    background: $bodyBgColor;
  }
}

.title-med {
  font-size: $largeFontSize;
  font-weight: 600;
}

.title {
  font-size: 22px;
  font-weight: 500;
  color: $textColor;  
  
  &.smallest {
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.smaller {
    font-size: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.smaller-wrap {
    font-size: 18px;
  }

  &.display-block {
    display: block !important;
  }
}

.subtitle {
  font-size: 20px;
  font-weight: 400;
}

td {
  word-break: keep-all;
  div {
    span {
      white-space: nowrap;
    }

    a {
      white-space: nowrap;
    }
  }
}


.job-sub-step-title {
  padding-top: 10vh;
  padding-bottom:2.5vh;
  font-size: 36px;
  color: $textColor;
}

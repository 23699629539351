@use "palette";
@use 'qdc-variables.scss';

$material-icon-xxxs-size: 12px;
$material-icon-xxs-size: 14px;
$material-icon-xs-size: 16px;
$material-icon-sm-size: 18px;
$material-icon-md-size: 20px;
$material-icon-default-size: 24px;
$material-icon-lg-size: 28px;
$material-icon-xl-size: 48px;
$material-icon-xxl-size: 96px;

@mixin icons($size: $material-icon-default-size) {
	font-size: $size;
}

@mixin material-icons() {
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	vertical-align: middle;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	color: palette.$white;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: space-evenly;
	color: qdc-variables.$iconColor;

	&.clicked {
		border-radius: 50%;
		height: 32px;
		width: 32px;
		background-color: qdc-variables.$primaryColorHoverColor;
	}

	@include icons();

	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently supported by Chrome and Opera */

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: "liga";
}

@mixin material-icons-outlined() {
	@include material-icons();
	font-family: "Material Icons Outlined";

	&.dark {
		color: qdc-variables.$iconColorDark;
	}

	&.green {
		color: qdc-variables.$landingHomeGreen;
	}
}

// use this class for regular filled icons
// by default icons will be white but you can 
// make them dark by adding the dark class below
.material-icons {
	@include material-icons();

	&.dark {
		color: qdc-variables.$iconColorDark;
	}

	&.green {
		color: qdc-variables.$landingHomeGreen;
	}

	&.primaryLight {
		color: qdc-variables.$iconColorPrimaryLight;
	}
}

// use this class for outlined icons
.material-icons-outlined {
	@include material-icons-outlined();
}

.material-icons.md-12,
.material-icons-outlined.md-12 {
	@include icons($material-icon-xxxs-size);
}

.material-icons.md-14,
.material-icons-outlined.md-14 {
	@include icons($material-icon-xxs-size);
}

.material-icons.md-16,
.material-icons-outlined.md-16 {
	@include icons($material-icon-xs-size);
}

.material-icons.md-18,
.material-icons-outlined.md-18 {
	@include icons($material-icon-sm-size);
}

.material-icons.md-20,
.material-icons-outlined.md-20 {
	@include icons($material-icon-md-size);
}

.material-icons.md-24,
.material-icons-outlined.md-24 {
	@include icons($material-icon-default-size);
}

.material-icons.md-36,
.material-icons-outlined.md-36 {
	@include icons($material-icon-lg-size);
}

.material-icons.md-48,
.material-icons-outlined.md-48 {
	@include icons($material-icon-xl-size);
}

.material-icons.md-96,
.material-icons-outlined.md-96 {
	@include icons($material-icon-xxl-size);
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/MaterialIcons-Regular.eot');
  /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url('/assets/fonts/MaterialIcons-Regular.woff2') format('woff2'),
    url('/assets/fonts/MaterialIcons-Regular.woff') format('woff'),
    url('/assets/fonts/MaterialIcons-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/MaterialIcons-Outlined.eot');
  /* For IE6-8 */
  src: local('Material Icons Outlined'),
    local('MaterialIcons-Outlined'),
    url('/assets/fonts/MaterialIcons-Outlined.woff2') format('woff2'),
    url('/assets/fonts/MaterialIcons-Outlined.woff') format('woff'),
    url('/assets/fonts/MaterialIcons-Outlined.ttf') format('truetype');
}

@font-face {
  font-family: "Qualcomm Next";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url('../fonts/QualcommNext/QualcommNext-Thin.woff') format("woff");
}
@font-face {
  font-family: "Qualcomm Next";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('../fonts/QualcommNext/QualcommNext-Regular.woff') format("woff");
}
@font-face {
  font-family: "Qualcomm Next";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url('../fonts/QualcommNext/QualcommNext-Medium.woff') format("woff");
}

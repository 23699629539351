.light {
  // Light Theme Colors
  --ltprimaryColor: #1C73C9;
  --ltprimaryLightColor: #F7F9FA;
  --ltprimaryDarkColor: #F2F5F7;
  --ltprimaryDarkerColor: #ebeef0;
  --ltsecondaryDarkColor: #fff;

  // Semantic
  --red: #E01B32;
  --yellow: #EB990C;
  --green: #319C27;
  --blue: #3253DC;
  --gray: #626B85;
  --gold: darkgoldenrod;

  // Secondary
  --secondaryRed: #E04C1B;
  --orange: #E67409;
  --yellowGreen: #80B012;
  --secondaryGreen: #279C4E;
  --teal: #0EABAB;
  --secondaryBlue: #1C73C9;
  --purple: #563BDB;
  --fuscia: #A321C4;
  --magenta: #C928A1;
  --secondaryGray: #3A3E4A;

  // Text Colors
  --ltTextColor4: rgba(0, 0, 0, 0.04);
  --lttextColor5: rgba(0, 0, 0, .05);
  --lttextColor30: rgba(0, 0, 0, .3);
  --lttextColor95: rgba(0, 0, 0, .95);

  --ltDeviceCardBgColor: #FAFBFC;
  --ltDeviceCardHoverBgColor: darken(--ltDeviceCardBgColor, 10%);

  // Main
  --primaryColor: var(--ltprimaryColor);
  --primaryLightColor: var(--ltprimaryLightColor);
  --primaryDarkColor: var(--ltprimaryDarkColor);
  --primaryDarkerColor: var(--ltprimaryDarkerColor);
  --secondaryDarkColor: var(--ltsecondaryDarkColor);

  --text-color: black;
  --inverse-text-color: rgba(255, 255, 255, .88);
  --textColor5: var(--lttextColor5);
  --textColorHover: #3c83f6;
  --textFadedColor: black;
  --linkColor: #2797c5;
  --borderColor: #ced4da;

  --sectionHeadingTextColor: #313131;

  --bodyBgColor: var(--ltprimaryDarkColor);
  --overlayBgColor: var(--bodyBgColor);
  --overlayBgOpaqueColor: rgb(0 0 0 / 9%);
  --boxBorder: rgba(18, 19, 25, 0.3);

  // Top Bar
  --topbarBgColor: white;
  --topbarFontColor: #212639;
  --topbarMenuFontColor: #505872;
  --topbarMenuFontColorHover: #363b4d;
  --toolBarColor: $primaryDarkColor;
  --topBarBorder: lightgray;
  --hightlightMenuColor: #1C73C9;

  // Table Colors
  --tableHoverColor: #e9ecef;
  --tableTextHoverColor: #495057;
  --tableBgColor: var(--secondaryDarkColor);
  --tableBorderColor: var(--textColor5);
  --disabledColor: #C4C6C8;
  --dividersColor: #C0CAD1;
  --pagerBackground: white;
  --tableHeaderBackgroundColor: #ebeef0;
  --tableHeaderTextColor: #343a40; // default primeng color
  --checkBoxBorder: rgba(18, 19, 25, 0.3); //#ced4da;

  --paginatorBackground: #EFF6FF;
  --paginatorColor: #1d4ed8;
  --paginatorHoverIconColor: #343a40;
  --paginatorHoverBorderColor: #e9ecef;

  --gridIcon: #6C757D;
  --gridIconHover: #ffffff;
  --gridIconActive: #4076FF;

  --skeletonColor: #dee2e6;

  // Button
  --buttonOutlineHover: var(--textColor5);
  --buttonHoverColor: #2142C4;
  --buttonColorPrimaryHover: #2142C4;
  --buttonColorPrimaryDisabled: rgba(18, 19, 25, 0.15);
  --buttonColorPrimaryTextDisabled: rgba(18, 19, 25, 0.4);
  --buttonBorderOutlineColor: var(--lttextColor30);
  --buttonColorGhostEnabled: white;
  --toolbarDisabledButtonColor: #a5a9ad;
  --toolbarGhostButtonColor: white;

  // Cards
  --cardBgColor: var(--ltDeviceCardBgColor);
  --deviceCardBgColor: var(--ltDeviceCardBgColor);
  --deviceCardHoverBgColor: var(--ltDeviceCardHoverBgColor);

  // Stepper variables
  --stepper-background: #0B2742;
  --stepper-border-bottom: rgba(18, 19, 25, 0.08);
  --step-label-active: white;
  --step-label-color: #B6BDCD;

  // Job Report
  --detailBorderColor: var(--textColor5);
  --detailBorderColorOpaque: var(--ltTextColor4);
  --executionContainer: white;
  --sshColor: #cc9037;
  --copyCodeBackground: #d0d2d3;
  --copyCodeBorderColor: rgba(0, 0, 0, 0.25);
  --copyIconColor: #495057;
  --copyIconBorderColor: rgba(0, 0, 0, 0.25);

  // Ace editor
  --aceColor: white;

  //Icon Colors
  --iconColorDark: #595f64;
  --initialsIconColor: #d0d2d3;
  --svgColor: var(--lttextColor95);

  // Inputswitch Colors
  --sliderBackground: #ced4da;
  --sliderOnBackground: #3B82F6;
  --sliderOnColor: #ffffff;
  --sliderOffColor: #ffffff;

  // Terms colors
  --termsConditionsContainer: rgb(183 183 183 / 10%);
  --termsBorderColor: #c1c1c1;
}

@import './theme/dark.scss';
@import './theme/light.scss';

// Figma color pallet
// Neutral Colors
$white: #FFFFFF;
$lightestGray: #EAEAEA;
$lighterGray: #d0d2d3;
$lightGray: #A6A8AB;
$gray: #808080;
$darkGray: #525252;
$darkerGray: #313131;
$black: #000000;

// for code
$fcRed: #FF7081;
$fcRedOrange: #FF8C66;
$fcOrange: #FFA34D;
$fcYellow: #FFC96B;
$fcYellowGreen: #B6D964;
$fcGreen: #76DB98;
$fcTeal: #63E0E0;
$fcBlue: #7AB8F5;
$fcPurple: #A796FA;
$fcFuscia: #D37DE8;
$fcMagenta: #E685CD;

// #region Themed Colors

// Semantic
$red: var(--red, #E01B32);
$yellow: var(--yellow, #EB990C);
$green: var(--green, #319C27);
$blue: var(--blue, #3253DC);
$gray: var(--gray, #626B85);

// QUI Semantic
$q-informative: #7ba0ff;
$q-warning: #eeb204;
$q-negative: #ef4351;

// Secondary
$secondaryRed: var(--secondaryRed, #E04C1B);
$orange: var(--orange, #E67409);
$orangeLighter: var(--orange, #ffa444);
$yellowGreen: var(--yellowGreen, #80B012);
$secondaryGreen: var(--secondaryGreen, #279C4E);
$teal: var(--teal, #0EABAB);
$secondaryBlue: var(--secondaryBlue, #1C73C9);
$purple: var(--purple, #563BDB);
$fuscia: var(--fuscia, #A321C4);
$magenta: var(--magenta, #C928A1);
$secondaryGray: var(--secondaryGray, #3A3E4A);
$gold: var(--gold, darkgoldenrod);

// Opaque
$opaqueWhite50: rgba(255, 255, 255, 0.5);

$primaryColor: var(--primaryColor, #1C73C9);
$primaryLightColor: var(--primaryLightColor, #F7F9FA);
$primaryLighterColor: var(--primaryLighterColor, hsl(217, 100%, 94%));
$primaryDarkColor: var(--primaryDarkColor, #F2F5F7);
$primaryDarkerColor: var(--primaryDarkerColor, #ebeef0);
$primaryColorHoverColor: var(--primaryColorHoverColor, $lighterGray);

$highlightColor: var(--highlightColor, #D7F5D3);
$cancelColor: var(--cancelColor, #858585);
$cancelLightColor: var(--cancelLightColor, #e9e9e9);
$cancelDarkColor: var(--cancelDarkColor, #505050);
$secondaryDarkColor: var(--secondaryDarkColor, #fff);

$textColor: var(--text-color, black);
$sshTextHighlight: var(--sshColor, #cc9037);
$copyCodeBackground: var(--copyCodeBackground, #d0d2d3);
$copyCodeBorderColor: var(--copyCodeBorderColor, rgba(0, 0, 0, 0.25));
$copyIconColor: var(--copyIconColor, 495057);
$copyIconBorderColor: var(--copyIconBorderColor, rgba(0, 0, 0, 0.25));
$inverseTextColor: var(--inverse-text-color, rgba(255, 255, 255, .88));
$textColor5: var(--textColor5, rgba(0, 0, 0, .05));
$textColorHover: var(--textColorHover, #3c83f6);
$textFadedColor: var(--textFadedColor, black);
$linkColor: var(--linkColor, #2797c5);
$borderColor: var(--borderColor, #ced4da);

$sectionHeadingTextColor: var(--sectionHeadingTextColor, $darkerGray);

$bodyBgColor: var(--bodyBgColor, #F2F5F7);
$overlayBgColor: var(--overlayBgColor, $bodyBgColor);
$overlayBgOpaqueColor: var(--overlayBgOpaqueColor, rgb(0 0 0 / 9%));
$boxBorder: var(--boxBorder, rgba(18, 19, 25, 0.3));

// Top Bar Colors
$topbarBgColor: var(--topbarBgColor, $white);
$topbarFontColor: var(--topbarFontColor, #212639);
$topbarMenuFontColor: var(--topbarMenuFontColor, #505872);
$topbarMenuFontColorHover: var(--topbarMenuFontColorHover, #363b4d);
$toolBarColor: var(--toolBarColor, $primaryDarkColor);
$topBarBorder: var(--topBarBorder, lightgray);
$hightlightMenuColor: var(--hightlightMenuColor, #1C73C9);

// Table Colors
$tableHoverColor: var(--tableHoverColor, #e9ecef);
$tableTextHoverColor: var(--tableTextHoverColor, #495057);
$tableBgColor: var(--tableBgColor, $secondaryDarkColor);
$tableBorderColor: var(--tableBorderColor, $textColor5);
$disabledColor: var(--disabledColor, #C4C6C8);
$dividersColor: var(--dividersColor, #C0CAD1);
$pagerBackground: var(--pagerBackground, white);
$tableHeaderBackgroundColor: var(--tableHeaderBackgroundColor, #ebeef0);
$tableHeaderTextColor: var(--tableHeaderTextColor, #343a40); // default primeng color
$checkBoxBorder: var(--checkBoxBorder, rgba(18, 19, 25, 0.3));

// Paginator
$paginatorBackground: var(--paginatorBackground, #EFF6FF);
$paginatorColor: var(--paginatorColor, #1d4ed8);
$paginatorHoverIconColor: var(--paginatorHoverIconColor, #343a40);
$paginatorHoverBorderColor: var(--paginatorHoverBorderColor, #e9ecef);

// Grid Icons
$gridIcon: var(--gridIcon, #6C757D);
$gridIconHover: var(--gridIconHover, #ffffff);
$gridIconActive: #4076FF;

// Skeleton
$skeletonColor: var(--skeletonColor, #dee2e6);

// Button
$buttonColorPrimary: #3253DC;
$buttonTextColor: $textColor;
$buttonOutlineHover: var(--buttonOutlineHover, $textColor5);
$buttonHoverColor: var(--buttonHoverColor, #2142C4);
$buttonColorPrimaryHover: var(--buttonColorPrimaryHover, #2142C4);
$buttonColorPrimaryDisabled: var(--buttonColorPrimaryDisabled, rgba(18, 19, 25, 0.15));
$buttonColorPrimaryTextDisabled: var(--buttonColorPrimaryTextDisabled, rgba(18, 19, 25, 0.4));
$buttonBorderOutlineColor: var(--buttonBorderOutlineColor, rgba(0, 0, 0, .3));
$buttonColorGhostEnabled: var(--buttonColorGhostEnabled, white);
$endSessionColor: #E5495C;
$toolbarDisabledButtonColor: var(--toolbarDisabledButtonColor, white);
$toolbarGhostButtonColor: var(--toolbarGhostButtonColor, white);

// Cards
$cardBgColor: var(--cardBgColor, $secondaryDarkColor);
$deviceCardBgColor: var(--deviceCardBgColor, #f2f5f7);
$deviceCardHoverBgColor: var(--deviceCardHoverBgColor, #fafdff);
$titleBorderColor: var(--titleBorderColor, inherit);

// Carousel
$carouselArrowColor: #6c757d;
$carouselArrowHoverColor: #343a40;

// term and condition background
$termsBackground: var(--termsConditionsContainer, inherit);
$termsBorder: var(--termsBorderColor, inherit);

// Stepper variables
$stepper-background: var(--stepper-background, #0B2742);
$stepper-border-bottom: var(--stepper-border-bottom, #EAEDF3);
$step-label-active: var(--step-label-active, $white);
$step-label-color: var(--step-label-color, #B6BDCD);

// Job Report
$jobReportToolbarColor: $stepper-background;
$detailBorderColor: var(--detailBorderColor, rgba(0, 0, 0, 0.05));
$detailBorderColorOpaque: var(--detailBorderColorOpaque, rgba(0, 0, 0, 0.04));
$executionContainer: var(--executionContainer, white);

// Ace Editor
$aceColor: var(--aceColor, white);

// Icon Colors
$iconColorDark: var(--iconColorDark, #595f64);
$initialsIconColor: var(--initialsIconColor, #d0d2d3);
$svgColor: var(--svgColor, rgba(0, 0, 0, 0.95));

// Input Switch Colors
$sliderBackground: var(--sliderBackground, #ced4da);
$sliderOnBackground: var(--sliderOnBackground, #3B82F6);
$sliderOnColor: var(--sliderOnColor, #ffffff);
$sliderOffColor: var(--sliderOffColor, #ffffff);

// Custom Scrollbar Colors
$scrollbarBackground: var(--scrollbarBackground, rgba(0, 0, 0, 0.2));

// TODO: make vars?
$landingHomeGreen: #5c7e72;
$dtDarkBlue: #495057;

// #endregion Themed Colors


// #region Constant Colors

// Icon Colors
$iconDarkOrange: #cc9037;
$iconDarkRed: #bb2424;
$iconDarkYellow: #e4c14f;

$treeFolder: $teal;
$treeFile: $blue;

// Stepper variables
$step-square-border: #8790A7;
$stepper-hr-border: $step-square-border;
$stepper-square-color: #B6BDCD;
$step-square-active-background: $white;
$step-square-active-color: #212639;
$stepper-valid-icon-background: $white;

// Job Report
$endSessionRed: #E6495C;
$endSessionHoverRed: #be3b4a;

// Text Colors
$iconColor: $white;
$iconColorPrimaryLight: $primaryLightColor;
$errorText: #bc101e;

// Basic Test Result Colors
$test-result-passed: hsl(122, 39%, 49%);
$test-result-failed: hsl(0, 75%, 54%);
$test-result-other: hsl(33, 84%, 67%);
$test-result-pending: hsl(0, 0%, 73%);

// Extended Test Result Colors
$test-result-blocked: #f4e842;
$test-result-canceled: hsl(0, 75%, 40%);
$test-result-error: hsl(0, 75%, 25%);
$test-result-skipped: #dada0b;
$test-result-analysis-error: #430a0a;
$test-result-noresult: #f2f2f2;
$test-result-notrun: #737373;
$test-result-unsupported: #ffff99;
$test-result-timedout: #cc7a00;
$test-result-setup-failed: #ac3973;
$test-result-completed: #4682b4;
$test-result-informational: #72a8ff;

// Message Colors
$messageColorWarning: #ffe979;
$messageColorInfo: #d5e5ff;

// WS-SCRCPY Colors
$scrcpyGray: hsl(199, 17%, 46%);
$scrcpyGreen: hsl(172, 100%, 37%);

// Landing Page
$landingPageDark: #121319;


// #endregion Constant Colors


// #region Non-color vars


// Font
// This replaces the font of every instance of the specified unicode character(s)
// Useful for registered symbol and other trademark symbols
// Roboto does not have these symbols superscripted by default but Qualcomm Next does
@font-face {
  font-family: 'Trademark';
  font-style: normal;
  font-weight: 100;
  src: url('../../assets/fonts/QualcommNext/QualcommNext-Regular.woff') format("woff");
  unicode-range: U+00AE;
}

$fontFamily: 'Trademark', 'Roboto Flex';
$fontVariationSettings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;

// Layout
$sidePadding: calc(2em + 10vw);
$jobDetailsWidth: 280px;

// Opacity
$ghostOpacity: 0.6;

// Borders
$borderRadiusLarge: 8px;
$borderRadiusMedium: 5px;
$borderRadiusSmall: 3px;

// Table
$tableRowHeight: 32px;
$tableHeaderHeight: 24px;

// Top Bar
$topbarHeight: 55px;
$toolbarHeight: 56px;
$jobReportToolbarTop: 44px;
$topbarZIndex: 1075; // Greater than --q-zindex-dropdown, modal, popover. Less than tooltip, toast, alert-banner

// Buttons
$buttonBorderOutline: 1px solid $buttonBorderOutlineColor;

// Cards
$cardBorderRadius: 4px;

//Font Sizes
$extraSmallFontSize: 10px;
$smallFontSize: 12px;
$mediumFontSize: 14px;
$defaultFontSize: 16px;
$largeFontSize: 18px;
$largerFontSize: 20px;
$largestFontSize: 26px;
$largestFontSize36: 36px;
$largestFontSize46: 46px;

$xLargeLineHeight: 28px;
$largeLineHeight: 26px;
$mediumLineHeight: 24px;
$smallLineHeight: 20px;
$extraSmallLineHeight: 18px;

$xLargeRowHeight: 420px;
$largeRowHeight: 280px;
$mediumRowHeight: 140px;
$smallRowHeight: 20px;
$extraSmallRowHeight: 14px;

$defaultFontWeight: 400;
$mediumFontWeight: 560;
$largeFontWeight: 600;
$largerFontWeight: 660;

//Accordion
$accordionFontColor: var(--accordionFontColor, #FFFFF2);

//QDialog
$dialogBackDropColor: #0006;



// #endregion Non-color vars

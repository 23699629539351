.dark {

  // Dark Theme Colors
  --dtprimaryColor: #1C73C9;
  --dtprimaryLightColor: #2E313B;
  --dtprimaryDarkColor: #121319;
  --dtsecondaryDarkColor: #20232B;

  // Semantic
  --red: #E6495C;
  --yellow: #EBAA3B;
  --green: #55AB4D;
  --blue: #7BA0FF;
  --gray: #8790A7;
  --gold: goldenrod;

  // Secondary
  --secondaryRed: #E67049;
  --orange: #E68B37;
  --yellowGreen: #91B837;
  --secondaryGreen: #4DAB6C;
  --teal: #33B8B8;
  --secondaryBlue: #4A91D9;
  --purple: #7D69E0;
  --fuscia: #BD50D9;
  --magenta: #D957B8;
  --secondaryGray: #8B929F;

  // Text Colors
  --dttextColor: rgba(255, 255, 255, .88);
  --dttextColor5: rgba(255, 255, 255, .05);
  --dttextColor15: rgba(255, 255, 255, 0.15);
  --dttextColor20: rgba(255, 255, 255, 0.2);
  --dttextColor30: rgba(255, 255, 255, .3);
  --dttextColor37: rgba(255, 255, 255, .37);
  --dttextColor60: rgba(255, 255, 255, .6);
  --dttextColor95: rgba(255, 255, 255, .95);

  --dtDeviceCardBgColor: #20232b;
  --dtDeviceCardHoverBgColor: #282c36;

  // Dark themed color values

  --primaryColor: var(--dtprimaryColor);
  --primaryDarkColor: var(--dtprimaryDarkColor);
  --primaryLightColor: var(--dtprimaryLightColor);
  --primaryDarkerColor: var(--dtsecondaryDarkColor);
  --secondaryDarkColor: var(--dtsecondaryDarkColor);

  --text-color: var(--dttextColor);
  --inverse-text-color: black;
  --textColor5: var(--dttextColor5);
  --textColorHover: var(--dttextColor);
  --textFadedColor: var(--dttextColor60);
  --linkColor: var(--textColor);
  --borderColor: var(--dttextColor30);

  --sectionHeadingTextColor: var(--dttextColor);

  --bodyBgColor: var(--dtprimaryDarkColor);
  --overlayBgColor: #3A3E4A;
  --overlayBgOpaqueColor: rgba(255, 255, 255, 0.1);
  --boxBorder: rgba(18, 19, 25, 0.3);

  // Top Bar Colors
  --topbarBgColor: var(--dtprimaryLightColor);
  --topbarFontColor: white;
  --topbarMenuFontColor: var(--dttextColor60);
  --topbarMenuFontColorHover: var(--dttopbarFontColor);
  --toolBarColor: var(--dtprimaryDarkColor);
  --topBarBorder: var(--dtprimaryDarkColor);
  --hightlightMenuColor: white;

  // Table Colors
  --tableHoverColor: var(--dttextColor30);
  --tableTextHoverColor: #495057;
  --tableBgColor: var(--dtsecondaryDarkColor);
  --tableBorderColor: var(--textColor5);
  --disabledColor: var(--dttextColor15);
  --dividersColor: var(--dttextColor37);
  --pagerBackground: var(--dtsecondaryDarkColor);
  --tableHeaderBackgroundColor: var(--primaryDarkerColor);
  --tableHeaderTextColor: var(--dttextColor60);
  --checkBoxBorder: var(--dttextColor30);

  --paginatorBackground: #EDF0F2;
  --paginatorColor: var(--dtprimaryDarkColor);
  --paginatorHoverIconColor: var(--dttextColor60);
  --paginatorHoverBorderColor: var(--textColor37);

  --gridIcon: #6C757D;
  --gridIconHover: #343a40;
  --gridIconActive: #4076FF;

  --skeletonColor: var(--primaryLightColor);

  // Button
  --buttonOutlineHover: var(--textColor5);
  --buttonHoverColor: var(--dttextColor60);
  --buttonColorPrimaryHover: #4076FF;
  --buttonColorPrimaryDisabled: var(--dttextColor15);
  --buttonColorPrimaryTextDisabled: var(--dttextColor15);
  --buttonBorderOutlineColor: var(--dttextColor30);
  --buttonColorGhostEnabled: var(--dttextColor);
  --toolbarDisabledButtonColor: var(--q-text-1-disabled);
  --toolbarGhostButtonColor: var(--q-semantic-informative);

  // Cards
  --cardBgColor: var(--dtsecondaryDarkColor);
  --deviceCardBgColor: var(--dtDeviceCardBgColor);
  --deviceCardHoverBgColor: var(--dtDeviceCardHoverBgColor);
  --titleBorderColor: var(--dtprimaryDarkColor);

  // Stepper variables
  --stepper-background: var(--dtsecondaryDarkColor);
  --stepper-border-bottom: var(--dtprimaryDarkColor);
  --step-label-active: var(--dttextColor);
  --step-label-color: var(--dttextColor60);

  // Job Report
  --detailBorderColor: var(--dttextColor15);
  --detailBorderColorOpaque: rgba(255, 255, 255, 0.05);
  --executionContainer: var(--dtprimaryDarkColor);
  --sshColor: #7BA0FF;
  --copyCodeBackground: rgba(0, 0, 0, 0.2);
  --copyCodeBorderColor: rgba(255, 255, 255, 0.25);
  --copyIconColor: white;
  --copyIconBorderColor: rgba(255, 255, 255, 0.3);

  // Ace editor
  --aceColor: var(--dtsecondaryDarkColor);

  //Icon Colors
  --iconColorDark: var(--text-color);
  --initialsIconColor: var(--dttextColor20);
  --svgColor: var(--dttextColor95);

  // Inputswitch Colors
  --sliderBackground: var(--dtprimaryDarkColor);
  --sliderOnBackground: var(--dttextColor);
  --sliderOnColor: var(--dtprimaryDarkColor);
  --sliderOffColor: var(--dttextColor37);

  // Custom Scrollbar Colors
  --scrollbarBackground: var(--dttextColor20);

  // Terms Colors
  --termsConditionsContainer: rgb(0 0 0 / 10%);
  --termsBorderColor: #5c5c5c;
}

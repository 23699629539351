// Table Styling TODO: make common
/* You can add global styles to this file, and also import other style files */
@use './app/global-styles/qdc-variables.scss';
@import './app/global-styles/qdc-variables.scss';
@import "~@angular/cdk/overlay-prebuilt.css";

@tailwind components;
@tailwind utilities;

// Results State Colors
.completed,
.completed-successfully,
.passed,
.ready,
.bundle-upload-completed,
.successful {
  color: qdc-variables.$test-result-passed;
}

.running,
.maintenance,
.analysis-pending,
.bundle-uploading {
  color: qdc-variables.$test-result-other;
}

.update-required {
  color: qdc-variables.$test-result-informational;
}

.failed,
.completed-unsuccessfully,
.bundle-expired,
.bundle-canceled,
.unsuccessful {
  color: qdc-variables.$test-result-failed;
}

.completed-pending-analysis,
.pending {
  color: qdc-variables.$test-result-other;
}

.offline,
.submitted,
.setup {
  color: qdc-variables.$test-result-pending
}

// Extended Results State Colors, no color coordination for completed or noresult states
.blocked {
  color: qdc-variables.$test-result-blocked
}

.canceled {
  color: qdc-variables.$test-result-canceled
}

.error {
  color: qdc-variables.$test-result-error
}

.skipped {
  color: qdc-variables.$test-result-skipped
}

.analysiserror {
  color: qdc-variables.$test-result-analysis-error
}

.notrun {
  color: qdc-variables.$test-result-notrun
}

.unsupported {
  color: qdc-variables.$test-result-unsupported
}

.timedout {
  color: qdc-variables.$test-result-timedout
}

.setupfailed {
  color: qdc-variables.$test-result-setup-failed
}

.empty-text {
  opacity: qdc-variables.$ghostOpacity;
  font-style: italic;
  font-variation-settings: 'ital' 1;
  font-synthesis: none;
  color: $textColor;
}

.error-text {
  color: qdc-variables.$errorText;
}

.primary-color {
  color: $primaryColor;
}

.gold {
  color: $gold !important;
}

.bold {
  font-weight: 600;
}

.clickable {
  cursor: pointer;
}

.job-report-title {
  color: $white;
  font-size: 18px;
  font-weight: 560;
  padding-left: 1rem;
}

.toolbar-title {
  font-size: 24px;
  color: qdc-variables.$sectionHeadingTextColor;
}

body a {
  text-decoration: none;
  text-overflow: ellipsis;
  color: $linkColor;
  font-weight: 560;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  align-items: center;
  letter-spacing: 0.01em;
  font-stretch: 110;
  font-variation-settings: $fontVariationSettings;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.clickable {
  cursor: pointer;
}

.med-clickable-icon {
  height: 65px;
  width: 70px;
  font-size: 60px;
  margin: auto;
}

.tooltip-info-icon {
  color: $iconColorDark;
  font-size: inherit;
}

.card-span {
  font-size: 58px;
  font-weight: bold;
  color: #7e93a9;
}

.card-span-subtitle {
  font-size: 17px;
  font-weight: 500;
  color: #575757;
}

.card-info-wrapper {
  position: relative;
}

.large-clickable-icon {
  height: 80px;
  width: 70px;
  font-size: 75px;
  margin: auto;
}

.card-info-icon {
  position: absolute;
  top: -25px;
  right: -5px;
  color: #d8d8d8;
}

.card-info-icon-flip {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #d8d8d8;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.animate-fade {
  animation: fadeIn 1s infinite alternate;
}

.pulsing-circle {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
  margin-top: -1px
}

.pulsing-circle.large {
  height: 25px;
  width: 25px
}

.pulsing-circle.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.p-card-header-template {
  padding: 1rem 1.25rem 0 1.25rem
}

@media (max-width: 1235px) {
  .hidden-sm {
    display: none !important;
  }
}

.filter-label {
  font-size: 20px !important;
  font-weight: 500;
  color: $textColor;
}

.progress-circle-container {
  width: -webkit-fill-available;
  width: -moz-available;
  height: 75vh;

  &.loading-session {
    min-width: 50vw;
  }

  &.content {
    height: 55vh
  }
}

.required-field::after {
  content: "*";
  color: $red;
  padding-left: 0.125em;
}

// Device Ribbons
.device-ribbon {
  background-color: $gray;

  &.x-small {
    font-size: 10px;

    .p-chip-text {
      font-size: 12px;
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }

  &.small {
    font-size: 10px;

    .p-chip-text {
      font-size: 10px
    }
  }

  &.new {
    background-color: $yellowGreen;
  }

  &.cameracapability {
    background-color: $fcRed;
  }

  &.comingsoon {
    background-color: $purple;
  }

  &.earlyaccess {
    background-color: $fcBlue;
  }

  &.virtualplatform {
    background-color: $orangeLighter;
  }

  .p-chip {
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 3px !important;
    background-color: inherit;

    .p-chip-text {
      white-space: nowrap;
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 600;
      letter-spacing: 0.03em;
      color: $inverseTextColor;
      font-stretch: 60;
      font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
    }
  }
}

.highlight-text {
  color: $sshTextHighlight;
}

.circle {
  height: 8px;
  width: 8px;
  background-color: $step-square-border;
  border-radius: 50%;
  display: inline-block;

  &.blue {
    background-color: $blue;
  }
}
